import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { useLocation, useNavigate } from 'react-router-dom';
import { globalContext } from '../../common/GlobalStorage/GlobalStorage';
import { IIndexable } from '../../common/Helpers/IIndexable';
import { validateElement, validateFinal } from '../../common/Validate/Validate';
import { stepMap } from '../../components/Menu/Menu';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import styles from './Kundendaten.module.scss';

const config: {[key: string]: any} = require('../../config.json');

interface KundendatenProps { }


export let _differentRiskLocation: any;

const Kundendaten: FC<KundendatenProps> = () => {
  const { globalState, dispatch } = useContext(globalContext);
  const [ modalIsOpen, setModalIsOpen ] = useState(false);
  const [ jumpToAfterModal, setJumpToAfterModal ] = useState('');
  let navigate = useNavigate();
  const location = useLocation();

  let _prename: any;
  let _name: any;
  let _birthdate: any;
  let _email: any;
  let _phone: any;
  let _street: any;
  let _housenumber: any;
  let _zipcode: any;
  let _city: any;
  let _insuredObjectStreet: any;
  let _insuredObjectHousenumber: any;
  let _insuredObjectZipcode: any;
  let _insuredObjectCity: any;
  let _applyPermanentlyInhabited: any;
  let _bankaccountholder: any;
  let _iban: any;
  let _applyPament: any;
  let _applyDSGVO: any;

  const checkNextStep = () => {
    let temp = validateFinal();
    console.log("Kundendaten, calculated step: ", temp);
    if (temp === 0 || temp > 6) {
      let jumpTo = (stepMap as IIndexable)[7];
      navigate(jumpTo);
    } else if (temp < 6) {
      let jumpTo = (stepMap as IIndexable)[temp];
      setJumpToAfterModal(jumpTo);
      setModalIsOpen(true);
    }
  };

  const validateAll = () => {
    if (!validate(_prename)
      && !validate(_name)
      && !validate(_birthdate)
      && !validate(_email)
      && !validate(_phone)
      && !validate(_street)
      && !validate(_housenumber)
      && !validate(_zipcode)
      && !validate(_city)
      && (_differentRiskLocation.checked || (
        !validate(_insuredObjectStreet)
        && !validate(_insuredObjectHousenumber)
        && !validate(_insuredObjectZipcode)
        && !validate(_insuredObjectCity)))
      && !validate(_applyPermanentlyInhabited, true, (globalState as IIndexable)["applyPermanentlyInhabited"])
      && !validate(_bankaccountholder)
      && !validate(_iban)
      && !validate(_applyPament, true, (globalState as IIndexable)["applyPament"])
      && !validate(_applyDSGVO, true, (globalState as IIndexable)["applyDSGVO"])) {
      checkNextStep();
    } else {
      // validate all
      validate(_prename, false);
      validate(_name, false);
      validate(_birthdate, false);
      validate(_email, false);
      validate(_phone, false);
      validate(_street, false);
      validate(_housenumber, false);
      validate(_zipcode, false);
      validate(_city, false);
      if (!_differentRiskLocation.checked) {
        validate(_insuredObjectStreet, false);
        validate(_insuredObjectHousenumber, false);
        validate(_insuredObjectZipcode, false);
        validate(_insuredObjectCity, false);
      } else {
        dispatch({ type: 'UPDATE_STATE', payload: false, key: 'insuredObjectStreetError' });
        dispatch({ type: 'UPDATE_STATE', payload: false, key: 'insuredObjectHousenumberError' });
        dispatch({ type: 'UPDATE_STATE', payload: false, key: 'insuredObjectZipcodeError' });
        dispatch({ type: 'UPDATE_STATE', payload: false, key: 'insuredObjectCityError' });
      }
      validate(_applyPermanentlyInhabited, false, (globalState as IIndexable)["applyPermanentlyInhabited"]);
      validate(_bankaccountholder, false);
      validate(_iban, false);
      validate(_applyPament, false, (globalState as IIndexable)["applyPament"]);
      validate(_applyDSGVO, false, (globalState as IIndexable)["applyDSGVO"]);
    }
  }

  const validate = (target: HTMLFormElement, scrollTo: boolean = true, value?: any) => {
    if (validateElement(target, value)) {
      dispatch({ type: 'UPDATE_STATE', payload: true, key: target.name + 'Error' });
      if (scrollTo) {
        const y = target.getBoundingClientRect().top + window.pageYOffset + -50;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
      return true;
    } else {
      dispatch({ type: 'UPDATE_STATE', payload: false, key: target.name + 'Error' });
      return false;
    }
  }

  useEffect(() => {
    dispatch({ type: 'UPDATE_STATE', payload: 6, key: 'currentStep' });

    if (location.state && (location.state as any).validateAll) {
      validateAll();
    }
  }, [location?.state]);

  const handleChange = (e: ChangeEvent) => {
    const target = e.target as HTMLFormElement;
    let value = target.value;

    if (target.name === 'iban') {
      value = value.toUpperCase();
    }

    dispatch({ type: 'UPDATE_STATE', payload: value, key: target.name });
    const error = validate(target, false);

    if (!error && target.name === 'email') {
      if (value !== globalState?.emailSended) {
        //send Emailvalidation pin
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + config.API_KEY
          },
          body: JSON.stringify({ email: value })
        };
        
        fetch(config.SERVER_URL + 'confirmmail', requestOptions)
          .then(response => {
            if(response.status === 200) {
              dispatch({ type: 'UPDATE_STATE', payload: value, key: 'emailSended' });
              dispatch({ type: 'UPDATE_STATE', payload: false, key: 'emailError' });
              dispatch({ type: 'UPDATE_STATE', payload: false, key: 'emailAPIError' });
            } else {
              dispatch({ type: 'UPDATE_STATE', payload: true, key: 'emailAPIError' });
              dispatch({ type: 'UPDATE_STATE', payload: '', key: 'emailSended' });
            }
          });
      }
    }
  };

  const handleChangeCheckbox = (e: ChangeEvent) => {
    const target = e.target as HTMLFormElement;
    let value = globalState ? !(globalState as IIndexable)[target.name] : true;
    dispatch({ type: 'UPDATE_STATE', payload: value, key: target.name });
    validate(target, false, value);
  };

  return (
    <>
    <div className={styles.Kundendaten} data-testid="Kundendaten">
      <div className="content bg-white p-20 w-96 mx-auto rounded">
        <h1 className="uppercase text-lightblue text-xl text-center relative pb-3.5">Deine Kundendaten</h1>
        <div className="form mt-8">
          <div className="grid grid-cols-1 gap-4">
            <label className="block input">
              <span className="">Vorname</span>
              <input
                type="text"
                className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                  ${globalState?.prenameError ? " error" : ""}`}
                ref={node => (_prename = node)}
                placeholder=""
                onChange={handleChange}
                name="prename"
                value={globalState?.prename}
              />
            </label>
            <label className="block input">
              <span className="">Nachname</span>
              <input
                type="text"
                className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                  ${globalState?.nameError ? " error" : ""}`}
                ref={node => (_name = node)}
                placeholder=""
                onChange={handleChange}
                name="name"
                value={globalState?.name}
              />
            </label>
            <label className="block input">
              <span className="">Geburtsdatum</span>
              <input
                type="date"
                className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                  ${globalState?.birthdateError ? " error" : ""}`}
                ref={node => (_birthdate = node)}
                placeholder=""
                onChange={handleChange}
                name="birthdate"
                value={globalState?.birthdate}
              />
            </label>
            <label className="block input">
              <span className="">E-Mail</span>
              <input
                type="email"
                className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                  ${globalState?.emailError || globalState?.emailAPIError ? " error" : ""}`}
                ref={node => (_email = node)}
                placeholder="deineEmail@adresse.com"
                onChange={handleChange}
                name="email"
                value={globalState?.email}
              />
            </label>
            <label className="block input">
              <span className="">Telefonnummer</span>
              <input
                type="tel"
                className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                  ${globalState?.phoneError ? " error" : ""}`}
                ref={node => (_phone = node)}
                placeholder=""
                onChange={handleChange}
                name="phone"
                value={globalState?.phone}
              />
            </label>

            <fieldset>
            {function () {
              if (globalState?.differentRiskLocation) {
                return (
                  <legend>Mein gemeldeter Wohnsitz</legend>
                  );
              } else {
                return (
                  <legend>Mein gemeldeter Wohnsitz / Versicherungsort</legend>
                  );
              }
            }.call(this)}
              <div className="grid grid-cols-2 gap-4">
                <label className="block input">
                  <span className="">Straße</span>
                  <input
                    type="text"
                    className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                    ${globalState?.streetError ? " error" : ""}`}
                    ref={node => (_street = node)}
                    placeholder=""
                    onChange={handleChange}
                    name="street"
                    value={globalState?.street}
                  />
                </label>
                <label className="block input">
                  <span className="">Hausnummer</span>
                  <input
                    type="text"
                    className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                      ${globalState?.housenumberError ? " error" : ""}`}
                    ref={node => (_housenumber = node)}
                    placeholder=""
                    onChange={handleChange}
                    name="housenumber"
                    value={globalState?.housenumber}
                  />
                </label>
                <label className="block input">
                  <span className="">PLZ</span>
                  <input
                    type="text"
                    className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                    ${globalState?.zipcodeError ? " error" : ""}`}
                    ref={node => (_zipcode = node)}
                    placeholder=""
                    onChange={handleChange}
                    name="zipcode"
                    value={globalState?.zipcode}
                  />
                </label>
                <label className="block input">
                  <span className="">Ort</span>
                  <input
                    type="text"
                    className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                      ${globalState?.cityError ? " error" : ""}`}
                    ref={node => (_city = node)}
                    placeholder=""
                    onChange={handleChange}
                    name="city"
                    value={globalState?.city}
                  />
                </label>
              </div>
            </fieldset>
            <label className="checkbox inline-flex items-top">
              <input
                type="checkbox"
                className="
                          rounded-sm
                          bg-formbg
                          border-0
                          focus:border-transparent focus:bg-gray-200
                          text-gray-700
                          focus:ring-0 focus:ring-offset-0
                        "
                ref={node => (_differentRiskLocation = node)}
                onChange={handleChangeCheckbox}
                name="differentRiskLocation"
                checked={!globalState?.differentRiskLocation}
              />
              <span className="ml-2">Ich werde meine Hardware Wallet an dem selben Ort wie meine Meldeadresse aufbewahren.</span>
            </label>
            {function () {
              if (globalState?.differentRiskLocation) {
                return (
                  <fieldset>
                    <legend>Abweichender Versicherungsort</legend>
                    <div className="grid grid-cols-2 gap-4">
                      <label className="block input">
                        <span className="">Straße</span>
                        <input
                          type="text"
                          className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                    ${globalState?.insuredObjectStreetError ? " error" : ""}`}
                          ref={node => (_insuredObjectStreet = node)}
                          placeholder=""
                          onChange={handleChange}
                          name="insuredObjectStreet"
                          value={globalState?.insuredObjectStreet}
                        />
                      </label>
                      <label className="block input">
                        <span className="">Hausnummer</span>
                        <input
                          type="text"
                          className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                      ${globalState?.insuredObjectHousenumberError ? " error" : ""}`}
                          ref={node => (_insuredObjectHousenumber = node)}
                          placeholder=""
                          onChange={handleChange}
                          name="insuredObjectHousenumber"
                          value={globalState?.insuredObjectHousenumber}
                        />
                      </label>
                      <label className="block input">
                        <span className="">PLZ</span>
                        <input
                          type="text"
                          className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                    ${globalState?.insuredObjectZipcodeError ? " error" : ""}`}
                          ref={node => (_insuredObjectZipcode = node)}
                          placeholder=""
                          onChange={handleChange}
                          name="insuredObjectZipcode"
                          value={globalState?.insuredObjectZipcode}
                        />
                      </label>
                      <label className="block input">
                        <span className="">Ort</span>
                        <input
                          type="text"
                          className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                      ${globalState?.insuredObjectCityError ? " error" : ""}`}
                          ref={node => (_insuredObjectCity = node)}
                          placeholder=""
                          onChange={handleChange}
                          name="insuredObjectCity"
                          value={globalState?.insuredObjectCity}
                        />
                      </label>
                    </div>
                  </fieldset>
                );
              }
            }.call(this)}
            <label className="checkbox inline-flex items-top">
              <input
                type="checkbox"
                className={`
                          rounded-sm
                          bg-formbg
                          border-0
                          focus:border-transparent focus:bg-gray-200
                          text-gray-700
                          focus:ring-0 focus:ring-offset-0
                          ${globalState?.applyPermanentlyInhabitedError ? " error" : ""}`}
                ref={node => (_applyPermanentlyInhabited = node)}
                onChange={handleChangeCheckbox}
                name="applyPermanentlyInhabited"
                checked={globalState?.applyPermanentlyInhabited}
              />
              <span className="ml-2">Der Wohnsitz bzw. Versicherungsort wird ständig bewohnt und ist nicht länger als 120 Tage unbewohnt.</span>
            </label>
            <fieldset>
              <legend>Abrechnungskonto</legend>
              <div className="grid grid-cols-1 gap-4">
                <label className="block input">
                  <span className="">Kontoinhaber</span>
                  <input
                    type="text"
                    className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                    ${globalState?.bankaccountholderError ? " error" : ""}`}
                    ref={node => (_bankaccountholder = node)}
                    placeholder=""
                    onChange={handleChange}
                    name="bankaccountholder"
                    value={globalState?.bankaccountholder}
                  />
                </label>
                <label className="block input">
                  <span className="">IBAN</span>
                  <input
                    type="text"
                    className={`mt-1 block w-full rounded bg-formbg border-0 focus:ring-formbg focus:bg-white focus:ring-1 focus:border-1
                      ${globalState?.ibanError ? " error" : ""}`}
                    ref={node => (_iban = node)}
                    placeholder=""
                    onChange={handleChange}
                    name="iban"
                    value={globalState?.iban}
                  />
                </label>
              </div>
            </fieldset>
            <hr></hr>
            <label className="checkbox inline-flex items-top">
              <input
                type="checkbox"
                className={`
                          rounded-sm
                          bg-formbg
                          border-0
                          focus:border-transparent focus:bg-gray-200
                          text-gray-700
                          focus:ring-0 focus:ring-offset-0
                          ${globalState?.applyPamentError ? " error" : ""}`}
                ref={node => (_applyPament = node)}
                onChange={handleChangeCheckbox}
                name="applyPament"
                checked={globalState?.applyPament}
              />
              <span className="ml-2">Ich ermächtige die ELEMENT Insurance AG und ihren Zahlungsdienstleister Stripe Payments Europe Ltd. fällige Beiträge per SEPA-Lastschriftmandat von meinem Konto einzuziehen.</span>
            </label>
            <label className="checkbox inline-flex items-top">
              <input
                type="checkbox"
                className={`
                          rounded-sm
                          bg-formbg
                          border-0
                          focus:border-transparent focus:bg-gray-200
                          text-gray-700
                          focus:ring-0 focus:ring-offset-0
                          ${globalState?.applyDSGVOError ? " error" : ""}`}
                ref={node => (_applyDSGVO = node)}
                onChange={handleChangeCheckbox}
                name="applyDSGVO"
                checked={globalState?.applyDSGVO}
              />
              <span className="ml-2">Ich habe die Hinweise zum <a className="fontinherit" href="https://www.bitsurance.eu/datenschutz/" target="_blank" rel="noreferrer">Datenschutz</a> gelesen und stimme der Verarbeitung meiner Daten zu.</span>
            </label>
          </div>
        </div>


        <button onClick={validateAll} className="rounded bg-lightblue text-white uppercase items-center"><span>Weiter</span>
          <svg className="w-4 h-4 ml-5" fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>

        <div className="footer">
        <h2>FAQ</h2>
        <Accordion allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Was passiert mit meinen Daten?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Datenschutz ist uns sehr wichtig. Wir erheben, speichern und verarbeiten nur Daten, die für die angegebenen Zwecke erforderlich sind. Erforderlich sind alle Daten die für die Darstellung des Versicherungsschutzes benötigt werden.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
      </div>
      </div>
    </div>
    {modalIsOpen ?
      <ModalDialog
        isOpen={modalIsOpen} 
        onClose={() => {
            console.log("onClose", jumpToAfterModal);
            navigate(jumpToAfterModal, { state: { validateAll: true } });
            setModalIsOpen(false);
        }}
        title={"Eingaben überprüfen"}
        msg={"Bitte prüfe Deine Angaben in einem vorherigen Schritt."}
        button={"OK"}
      ></ModalDialog> : ''}
    </>
  );
}

export default Kundendaten;
