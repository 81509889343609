import React, { FC } from 'react';
import { BrowserRouter, Link, Outlet, Route, Routes } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';

interface SwitchRouterProps {}

const Example = React.lazy(() => import("./../../pages/Example/Example.lazy"));

const Wallet = React.lazy(() => import("./../../pages/Wallet/Wallet.lazy"));
const Land = React.lazy(() => import("./../../pages/Land/Land.lazy"));
const Versicherungssumme = React.lazy(() => import("./../../pages/Versicherungssumme/Versicherungssumme.lazy"));
const Unterkonto = React.lazy(() => import("./../../pages/Unterkonto/Unterkonto.lazy"));
const Nachweis = React.lazy(() => import("./../../pages/Nachweis/Nachweis.lazy"));
const Kundendaten = React.lazy(() => import("./../../pages/Kundendaten/Kundendaten.lazy"));
const Zusammenfassung = React.lazy(() => import("./../../pages/Zusammenfassung/Zusammenfassung.lazy"));
const Unterlagen = React.lazy(() => import("./../../pages/Unterlagen/Unterlagen.lazy"));

const SwitchRouter: FC<SwitchRouterProps> = () =>  {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={<Wallet />}
        />
        <Route
          path="wallet"
          element={
            <React.Suspense fallback={<>...</>}>
              <Wallet />
            </React.Suspense>
          }
        />
        <Route
          path="wallet/:a"
          element={
            <React.Suspense fallback={<>...</>}>
              <Wallet />
            </React.Suspense>
          }
        />
        <Route
          path="land"
          element={
            <React.Suspense fallback={<>...</>}>
              <Land />
            </React.Suspense>
          }
        />
        <Route
          path="versicherungssumme"
          element={
            <React.Suspense fallback={<>...</>}>
              <Versicherungssumme />
            </React.Suspense>
          }
        />
        <Route
          path="unterkonto"
          element={
            <React.Suspense fallback={<>...</>}>
              <Unterkonto />
            </React.Suspense>
          }
        />
        <Route
          path="nachweis"
          element={
            <React.Suspense fallback={<>...</>}>
              <Nachweis />
            </React.Suspense>
          }
        />
        <Route
          path="kundendaten"
          element={
            <React.Suspense fallback={<>...</>}>
              <Kundendaten />
            </React.Suspense>
          }
        />
        <Route
          path="zusammenfassung"
          element={
            <React.Suspense fallback={<>...</>}>
              <Zusammenfassung />
            </React.Suspense>
          }
        />
        <Route
          path="unterlagen"
          element={
            <React.Suspense fallback={<>...</>}>
              <Unterlagen />
            </React.Suspense>
          }
        />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
    </BrowserRouter>
  );
};

function Layout() {
  return (
    <div className="main bg-gradient-to-r from-blue to-lightblue min-h-screen">
      <div className="bg bg-[url('assets/img/bg.svg')] bg-no-repeat bg-contain absolute w-96 h-96"></div>
      <Menu />
      <Outlet />
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default SwitchRouter;
