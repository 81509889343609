import { ActionType, GlobalStateInterface } from './types';
import { initialState } from './GlobalStorage';

const Reducer = (state: GlobalStateInterface, action: ActionType): any => {
  switch (action.type) {
    case 'UPDATE_STATE':
      //console.log('UPDATE_STATE action', action);
      const key = action.key ? action.key : 'default';
      let obj = {...state};
      (obj as any)[key] = action.payload;
      return obj;
    case 'PURGE_STATE':
      return initialState;
    default:
      return state;
  }
};

export default Reducer;
