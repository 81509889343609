import React, { FC, useContext } from 'react';
import styles from './Menu.module.scss';
import './Menu.module.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { globalContext } from '../../common/GlobalStorage/GlobalStorage';
import { validateFinal } from '../../common/Validate/Validate';
import { IIndexable } from '../../common/Helpers/IIndexable';

interface MenuProps { }

export const stepMap = {
  1: '/wallet',
  2: '/land',
  3: '/versicherungssumme',
  4: '/unterkonto',
  5: '/nachweis',
  6: '/kundendaten',
  7: '/zusammenfassung',
  8: '/unterlagen'
};

const Menu: FC<MenuProps> = () => {
  let navigate = useNavigate();
  const { globalState } = useContext(globalContext);
  const { t, i18n } = useTranslation(["ns1"]);
  const changeLanguageHandlerDE = (event: React.FormEvent) => {
    i18n.changeLanguage('de')
  };
  const changeLanguageHandlerEN = (event: React.FormEvent) => {
    i18n.changeLanguage('en')
  };

  //TODO step modal error dialog
  const stepClicked = (event: React.FormEvent, step: number) => {
    event.preventDefault();
    if(globalState?.currentStep === 8) {
      return;
    }
    let temp = validateFinal();
    if (temp !== 0 && temp <= step) {
      let jumpTo = (stepMap as IIndexable)[temp];
      navigate(jumpTo, { state: { validateAll: true } });
    } else {
      let jumpTo = (stepMap as IIndexable)[step];
      navigate(jumpTo);
    }
  };

  const nextClicked = (event: React.FormEvent) => {
    stepClicked(event, globalState?.currentStep + 1);
  };

  const prevClicked = (event: React.FormEvent) => {
    stepClicked(event, globalState?.currentStep - 1);
  };

  return (
    <div className={styles.Menu} data-testid="Menu">
      <div className="language">
        <span>&nbsp;</span>
      </div>
      <ul className="steps flex justify-center relative">
        {globalState?.currentStep <= 1 && (
          <li className="leer"></li>
        )}
        {globalState?.currentStep <= 2 && (
          <li className="leer"></li>
        )}
        {globalState?.currentStep <= 3 && (
          <li><NavLink onClick={(e) => stepClicked(e, 1)} to="/wallet" className={({ isActive }) => (isActive ? 'active' : 'inactive')}><span className={(globalState?.currentStep > 1 ? "complete" : "")}>1</span></NavLink></li>
        )}
        {globalState?.currentStep <= 4 && (
          <li><NavLink onClick={(e) => stepClicked(e, 2)} to="/land" className={({ isActive }) => (isActive ? 'active' : 'inactive')}><span className={(globalState?.currentStep > 2 ? "complete" : "")}>2</span></NavLink></li>
        )}
        {globalState?.currentStep <= 5 && (
          <li><NavLink onClick={(e) => stepClicked(e, 3)} to="/versicherungssumme" className={({ isActive }) => (isActive ? 'active' : 'inactive')}><span className={(globalState?.currentStep > 3 ? "complete" : "")}>3</span></NavLink></li>
        )}
        {globalState?.currentStep > 1 && globalState?.currentStep < 7 && (
          <li><NavLink onClick={(e) => stepClicked(e, 4)} to="/unterkonto" className={({ isActive }) => (isActive ? 'active' : 'inactive')}><span className={(globalState?.currentStep > 4 ? "complete" : "")}>4</span></NavLink></li>
        )}
        {globalState?.currentStep > 2 && globalState?.currentStep < 8 && (
          <li><NavLink onClick={(e) => stepClicked(e, 5)} to="/nachweis" className={({ isActive }) => (isActive ? 'active' : 'inactive')}><span className={(globalState?.currentStep > 5 ? "complete" : "")}>5</span></NavLink></li>
        )}
        {globalState?.currentStep > 3 && (
          <li><NavLink onClick={(e) => stepClicked(e, 6)} to="/kundendaten" className={({ isActive }) => (isActive ? 'active' : 'inactive')}><span className={(globalState?.currentStep > 6 ? "complete" : "")}>6</span></NavLink></li>
        )}
        {globalState?.currentStep > 4 && (
          <li><NavLink onClick={(e) => stepClicked(e, 7)} to="/zusammenfassung" className={({ isActive }) => (isActive ? 'active' : 'inactive')}><span className={(globalState?.currentStep > 7 ? "complete" : "")}>7</span></NavLink></li>
        )}
        {globalState?.currentStep > 5 && (
          <li><NavLink onClick={(e) => e.preventDefault()} to="/unterlagen" className={({ isActive }) => (isActive ? 'active' : 'inactive')}><span>8</span></NavLink></li>
        )}
        {globalState?.currentStep > 6 && (
          <li className="leer"></li>
        )}
        {globalState?.currentStep > 7 && (
          <li className="leer"></li>
        )}
        {/*
        <li><NavLink to="/wallet" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Wallet</NavLink></li>
        <li><NavLink to="/land" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Land</NavLink></li>
        <li><NavLink to="/versicherungssumme" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Versicherungssumme</NavLink></li>
        <li><NavLink to="/unterkonto" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Unterkonto</NavLink></li>
        <li><NavLink to="/nachweis" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Nachweis</NavLink></li>
        <li><NavLink to="/kundendaten" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Kundendaten</NavLink></li>
        <li><NavLink to="/zusammenfassung" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Zusammenfassung</NavLink></li>
        <li><NavLink to="/unterlagen" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Unterlagen</NavLink></li>
        */}
      </ul>
      <ul className="stepsbtn flex justify-center relative">

        {function () {
          if (globalState?.currentStep > 1 && globalState?.currentStep < 8) {
            return (
              <li className="prev" onClick={(e) => prevClicked(e)}>Zurück</li>
            );
          } else {
            return (<li className="nextprev"></li>);
          }
        }.call(this)}
        {globalState?.currentStep === 1 && (
          <li>Wallet</li>
        )}
        {globalState?.currentStep === 2 && (
          <li>Land</li>
        )}
        {globalState?.currentStep === 3 && (
          <li>Versicherungssumme</li>
        )}
        {globalState?.currentStep === 4 && (
          <li>Unterkonto</li>
        )}
        {globalState?.currentStep === 5 && (
          <li>Nachweis</li>
        )}
        {globalState?.currentStep === 6 && (
          <li>Kundendaten</li>
        )}
        {globalState?.currentStep === 7 && (
          <li>Zusammenfassung</li>
        )}
        {globalState?.currentStep === 8 && (
          <li>Unterlagen</li>
        )}
        {function () {
          if (globalState?.currentStep < 7) {
            return (
              <li className="next" onClick={(e) => nextClicked(e)}>Weiter</li>
            );
          } else {
            return (<li className="nextprev"></li>);
          }
        }.call(this)}
      </ul>
    </div>
  );
}

export default Menu;
